// DateContext.js
import React, { createContext, useState, useContext } from "react";

const DateContext = createContext();

export const useDate = () => useContext(DateContext);

export const DateProvider = ({ children }) => {
  const [currentDate, setCurrentDate] = useState(null);
  const [publicEventsOnDate, setPublicEventsOnDate] = useState(null);

  return (
    <DateContext.Provider
      value={{
        currentDate,
        setCurrentDate,
        publicEventsOnDate,
        setPublicEventsOnDate,
      }}
    >
      {children}
    </DateContext.Provider>
  );
};
