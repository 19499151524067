export const ITINERARY_DURATION_THRESHOLD = 2;
export const LOCAL_CACHE_EXPIRY = 3600 * 1000; // an hour

export const AFFLILIATE_PROGRAMMES = ["expedia"];

export const EXPEDIA_HOTEL_SEARCH_URL =
  "https://www.expedia.com.au/Hotel-Search";
export const EXPEDIA_FLIGHT_SEARCH_URL =
  "https://www.expedia.com.au/Flights-Search";
export const EXPEDIA_AFFILIATE_ID = "AU.DIRECT.PHG.1011l344883.1100l86802";
export const EXPEDIA_AFFILIATE_REF_ID = "1101lyoLTjAx";
