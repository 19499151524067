// DateContext.js
import React, { createContext, useState, useContext, useEffect } from "react";

const UserLocationContext = createContext();

export const useUserLocation = () => useContext(UserLocationContext);

export const UserLocationProvider = ({ children }) => {
  const [userCountry, setUserCountry] = useState("Australia"); // Initialize with the current date or whatever default you prefer
  const [userRegion, setUserRegion] = useState("New South Wales");
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    if ((userCountry && userRegion) || userCountry) {
      setUserLocation(
        userRegion ? `${userRegion}, ${userCountry}` : userCountry
      );
    }
  }, [userCountry, userRegion]);

  return (
    <UserLocationContext.Provider
      value={{
        userCountry,
        setUserCountry,
        userRegion,
        setUserRegion,
        userLocation,
      }}
    >
      {children}
    </UserLocationContext.Provider>
  );
};
