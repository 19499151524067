// UserLocationContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { useDate } from "../DateContext/DateContext";
import { LOCAL_CACHE_EXPIRY } from "../../util/settings";
import Airtable from "airtable";
import { getCookieConsentValue } from "react-cookie-consent";

const apiKey = process.env.REACT_APP_AIRTABLE_ACCESS_TOKEN;
const appID = process.env.REACT_APP_AIRTABLE_APP_ID;
const base = new Airtable({ apiKey }).base(appID);

const PublicEventsMetaContext = createContext();

export const usePublicEventsMeta = () => useContext(PublicEventsMetaContext);

export const PublicEventsMetaProvider = ({ children }) => {
  const [publicEventsMeta, setPublicEventsMeta] = useState([]);
  const { publicEventsOnDate } = useDate();
  const [publicEventsMetaLoading, setPublicEventsMetaLoading] = useState(true);

  useEffect(() => {
    const fetchPublicEventsMeta = async () => {
      try {
        setPublicEventsMetaLoading(true);
        const cookieConsent = getCookieConsentValue("vacaytoday:cookieConsent");
        const currentTime = new Date().getTime();
        const eventsMetaRecordsInCache =
          cookieConsent === "true"
            ? localStorage.getItem(
                `vacaytoday:eventsMetaRecords:${publicEventsOnDate[0]}`
              )
            : null;

        const cacheAge = eventsMetaRecordsInCache
          ? Math.abs(
              currentTime -
                parseFloat(
                  localStorage.getItem(
                    `vacaytoday:eventsMetaRecords:timestamp:${publicEventsOnDate[0]}` //vacaytoday:eventsMetaRecords_timestamp:rechMvKwRrvZfgaSg
                  )
                )
            )
          : 0;
        let records = [];
        if (eventsMetaRecordsInCache && cacheAge < LOCAL_CACHE_EXPIRY) {
          records = JSON.parse(eventsMetaRecordsInCache);
        } else {
          // Construct a formula to match any of the IDs in the list
          const idsFormula = `OR(${publicEventsOnDate
            .map((id) => `{RECORD_ID} = '${id}'`)
            .join(",")})`;
          // Fetch public events using the constructed formula
          records = await base("public_event_meta")
            .select({
              filterByFormula: idsFormula,
            })
            .all();

          if (cookieConsent === "true") {
            localStorage.setItem(
              `vacaytoday:eventsMetaRecords:${publicEventsOnDate[0]}`,
              JSON.stringify(records)
            );
            localStorage.setItem(
              `vacaytoday:eventsMetaRecords:timestamp:${publicEventsOnDate[0]}`,
              new Date().getTime()
            );
          }
        }

        // if (publicEventsOnDate && publicEventsOnDate.length > 0) {
        if (records.length > 0) {
          // Extract and set the public events data
          const eventsData = records.map((record) => record.fields);
          // debugger;
          setPublicEventsMeta(eventsData);
          setPublicEventsMetaLoading(false);
        } else {
          setPublicEventsMetaLoading(false);
        }
      } catch (error) {
        console.error("Error fetching public events meta: ", error);
      }
    };

    if (publicEventsOnDate) fetchPublicEventsMeta();
  }, [publicEventsOnDate]);

  return (
    <PublicEventsMetaContext.Provider
      value={{
        publicEventsMeta,
        setPublicEventsMeta,
        publicEventsMetaLoading,
      }}
    >
      {children}
    </PublicEventsMetaContext.Provider>
  );
};
