// DateContext.js
import React, { createContext, useState, useContext } from "react";

const DestinationContext = createContext();

export const useDestination = () => useContext(DestinationContext);

export const DestinationProvider = ({ children }) => {
  const [destinations, setDestinations] = useState(null); // Initialize with the current date or whatever default you prefer

  return (
    <DestinationContext.Provider value={{ destinations, setDestinations }}>
      {children}
    </DestinationContext.Provider>
  );
};
