// UserLocationContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { LOCAL_CACHE_EXPIRY } from "../../util/settings";
import { useUserLocation } from "../UserLocationContext/UserLocationContext";
import { useDestination } from "../DestinationContext/DestinationContext";
import Airtable from "airtable";
import { getCookieConsentValue } from "react-cookie-consent";

const apiKey = process.env.REACT_APP_AIRTABLE_ACCESS_TOKEN;
const appID = process.env.REACT_APP_AIRTABLE_APP_ID;
const base = new Airtable({ apiKey }).base(appID);

const PublicEventsContext = createContext();

export const usePublicEvents = () => useContext(PublicEventsContext);

export const PublicEventsProvider = ({ children }) => {
  const [userPublicEvents, setUserPublicEvents] = useState([]);
  const [publicEventsLoading, setPublicEventsLoading] = useState(false);
  const [destinationsPublicEvents, setDestinationsPublicEvents] = useState([]);
  const [locations, setLocations] = useState({});
  const { userLocation } = useUserLocation();
  const { destinations } = useDestination();

  useEffect(() => {
    const cookieConsent = getCookieConsentValue("vacaytoday:cookieConsent");
    const currentTime = new Date().getTime();
    const fetchUserPublicEvents = async () => {
      try {
        setPublicEventsLoading(true);
        const recordsInLocationInCache =
          cookieConsent === "true"
            ? localStorage.getItem(
                `vacaytoday:recordsInLocation:${userLocation}`
              )
            : null;

        const cacheAge = recordsInLocationInCache
          ? Math.abs(
              currentTime -
                parseFloat(
                  localStorage.getItem(
                    `vacaytoday:recordsInLocation:timestamp:${userLocation}`
                  )
                )
            )
          : 0;

        let records = [];
        if (recordsInLocationInCache && cacheAge < LOCAL_CACHE_EXPIRY) {
          records = JSON.parse(recordsInLocationInCache);
        } else {
          // Step 1: Query 'location' table to get location ID
          records = await base("location")
            .select({
              filterByFormula: `AND({name} = '${userLocation}')`,
            })
            .firstPage();
          if (cookieConsent === "true") {
            localStorage.setItem(
              `vacaytoday:recordsInLocation:${userLocation}`,
              JSON.stringify(records)
            );
            localStorage.setItem(
              `vacaytoday:recordsInLocation:timestamp:${userLocation}`,
              new Date().getTime()
            );
          }
        }

        if (records.length > 0) {
          setLocations((existingLocations) => {
            const name = `${userLocation}`;
            const id = records[0].fields.id;
            return {
              ...existingLocations, // Spread operator to copy existing state
              ...(existingLocations[name] === undefined && { [name]: id }), // Add new key-value pair if it doesn't exist
            };
          });
          // debugger;
          const publicEventIds = records[0].fields.public_event; // Assume the first record for simplicity
          if (publicEventIds && publicEventIds.length > 0) {
            // Construct a formula to match any of the IDs in the list
            const idsFormula = `OR(${publicEventIds
              .map((id) => `{RECORD_ID} = '${id}'`)
              .join(",")})`;
            // Fetch public events using the constructed formula
            const eventsRecords = await base("public_event")
              .select({
                filterByFormula: idsFormula,
              })
              .all();
            // Extract and set the public events data
            const eventsData = eventsRecords.map((record) => record.fields);
            if (cookieConsent === "true") {
              localStorage.setItem(
                `vacaytoday:userPublicEvents:${userLocation}`,
                JSON.stringify(eventsData)
              );
              localStorage.setItem(
                `vacaytoday:userPublicEvents:timestamp:${userLocation}`,
                new Date().getTime()
              ); // Update the timestamp of when the data was fetched
            }
            setUserPublicEvents(eventsData);
            setPublicEventsLoading(false);
          }
        } else {
          setPublicEventsLoading(false);
        }
      } catch (error) {
        setPublicEventsLoading(false);
        console.error("Error fetching public events: ", error);
      }
    };

    if (userLocation) {
      // Check if userPublicEvents are already stored in localStorage
      const userPublicEventsInCache =
        cookieConsent === "true"
          ? localStorage.getItem(`vacaytoday:userPublicEvents:${userLocation}`)
          : null;
      const cacheAge = userPublicEventsInCache
        ? Math.abs(
            currentTime -
              parseFloat(
                localStorage.getItem(
                  `vacaytoday:userPublicEvents:timestamp:${userLocation}`
                )
              )
          )
        : 0;
      if (userPublicEventsInCache && cacheAge < LOCAL_CACHE_EXPIRY) {
        setUserPublicEvents(JSON.parse(userPublicEventsInCache));
      } else {
        fetchUserPublicEvents();
      }
    }
  }, [userLocation]);

  useEffect(() => {
    const fetchDestinationsPublicEvents = async () => {
      try {
        setPublicEventsLoading(true);
        const destinationsFormula = `OR(${destinations
          .map((dest) => `{name} = '${dest}'`)
          .join(",")})`;

        let records = await base("location")
          .select({
            filterByFormula: destinationsFormula,
          })
          .firstPage();

        if (records.length > 0) {
          records.map((location) => {
            setLocations((existingLocations) => {
              const name = location.fields.name;
              const id = location.fields.id;
              return {
                ...(existingLocations[name] === undefined && { [name]: id }),
                ...existingLocations,
              };
            });
            return null;
          });
          const publicEventIds = records
            .map((record) => {
              return record.fields.public_event;
            })
            .flat();

          if (publicEventIds && publicEventIds.length > 0) {
            // Construct a formula to match any of the IDs in the list
            const idsFormula = `OR(${publicEventIds
              .map((id) => `{RECORD_ID} = '${id}'`)
              .join(",")})`;
            // Fetch public events using the constructed formula
            const eventsRecords = await base("public_event")
              .select({
                filterByFormula: idsFormula,
              })
              .all();

            // Extract and set the public events data
            const eventsData = eventsRecords.map((record) => record.fields);
            setDestinationsPublicEvents(eventsData);
            setPublicEventsLoading(false);
          }
        } else {
          setDestinationsPublicEvents([]);
          setPublicEventsLoading(false);
        }
      } catch (error) {
        setPublicEventsLoading(false);
        console.error("Error fetching public events: ", error);
      }
    };

    if (destinations) {
      fetchDestinationsPublicEvents();
    }
  }, [destinations]);

  return (
    <PublicEventsContext.Provider
      value={{
        userPublicEvents,
        setUserPublicEvents,
        destinationsPublicEvents,
        setDestinationsPublicEvents,
        locations,
        setLocations,
        publicEventsLoading,
      }}
    >
      {children}
    </PublicEventsContext.Provider>
  );
};
