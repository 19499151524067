import React, { Suspense, lazy } from "react";
import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { PublicEventsProvider } from "./context/PublicEventsContext/PublicEventsContext";
import { DateProvider } from "./context/DateContext/DateContext";
import { UserLocationProvider } from "./context/UserLocationContext/UserLocationContext";
import { DestinationProvider } from "./context/DestinationContext/DestinationContext";
import { PublicEventsMetaProvider } from "./context/PublicEventsMetaContext/PublicEventsMetaContext";

const Menu = lazy(() => import("./components/Menu/Menu"));
const Header = lazy(() => import("./components/Header/Header"));
const Controls = lazy(() => import("./components/Controls/Controls"));
const Visualisation = lazy(() =>
  import("./components/Visualisation/Visualisation")
);
const Footer = lazy(() => import("./components/Footer/Footer"));
const Consent = lazy(() => import("./components/Consent/Consent"));

const DestinationsPage = lazy(() => import("./pages/DestinationsPage"));
const DestinationPage = lazy(() => import("./pages/DestinationPage"));
const PublicEventPage = lazy(() => import("./pages/PublicEventPage"));
const AboutPage = lazy(() => import("./pages/AboutPage"));
const PrivacyPolicyPage = lazy(() => import("./pages/PrivacyPolicyPage"));
const ContactPage = lazy(() => import("./pages/ContactPage"));

function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <UserLocationProvider>
          <DestinationProvider>
            <PublicEventsProvider>
              <DateProvider>
                <PublicEventsMetaProvider>
                  <div id="outer-container" className="App">
                    <Header />
                    <Menu />
                    <div id="page-wrap">
                      <Routes>
                        <Route
                          path="/destinations"
                          element={<DestinationsPage />}
                        />
                        <Route
                          path="/destinations/:destinationSlug"
                          element={<DestinationPage />}
                        />
                        <Route
                          path="/destinations/:destinationSlug/:publicEventSlug"
                          element={<PublicEventPage />}
                        />
                        <Route path="/about" element={<AboutPage />} />
                        <Route
                          path="/privacy-policy"
                          element={<PrivacyPolicyPage />}
                        />
                        <Route path="/advertise" element={<ContactPage />} />
                        <Route
                          path="/"
                          element={
                            <>
                              <Controls />
                              <Visualisation />
                            </>
                          }
                        />
                      </Routes>
                      <Footer />
                      <Consent />
                    </div>
                  </div>
                </PublicEventsMetaProvider>
              </DateProvider>
            </PublicEventsProvider>
          </DestinationProvider>
        </UserLocationProvider>
      </Suspense>
    </Router>
  );
}

export default App;
